import React, { useEffect, useState } from 'react';
import { Document, Page, Text, View } from '@react-pdf/renderer';
import { invoicePdfStyle } from './invoice.style';
import { Address, Company, Invoice } from '../../../backend/careo-api';
import {
  convertSecondsToTime,
  formatDate,
  getDurationDifference,
} from '../../../utils';

type InvoicePDFProps = {
  invoice: Invoice;
  company?: Company;
};

export const InvoicePDF = ({ invoice, company }: InvoicePDFProps) => {
  const [data, setData] = useState<{
    clientAddress?: Address;
    reminderDate: string;
    candidateName: string;
    candidateRole: string;
    client: string;
    timings: {
      description: string;
      duration: number;
      payAmount: number;
      lineTotal: number;
    }[];
    onCall: {
      totalHours: number;
      fee: number;
      lineTotal: number;
    };
    totalLines: number;
    vat: {
      value: number;
      result: number;
    };
    finalResult: number;
  }>({} as any);

  useEffect(() => {
    const reminderDate = new Date(invoice.createdDate);
    reminderDate.setDate(reminderDate.getDate() + 30);
    const stringReminderDate = formatDate(reminderDate.toJSON());

    const candidateName = `${invoice.placement?.candidate?.title} ${invoice.placement?.candidate?.firstName} ${invoice.placement?.candidate?.lastName}`;
    const candidateRole = `${invoice.placement?.candidate?.designation} ${invoice.placement?.candidate?.grade}`;
    const client = `${invoice.placement?.job?.client.clientName} ${invoice.placement?.job?.client.clientSiteName} ${invoice.placement?.job?.client.address?.region}`;
    const clientAddress = invoice.placement?.job?.client.address;

    const timings = invoice.timesheet?.timings.map((el) => {
      const [hours1, minutes1] = el?.startTime?.split(':') ?? [];
      const [hours2, minutes2] = el?.endTime?.split(':') ?? [];
      const timesheetDate = new Date(el.date);
      const resultDate1 = new Date(
        timesheetDate.getFullYear(),
        timesheetDate.getMonth(),
        timesheetDate.getDate(),
        parseInt(hours1),
        parseInt(minutes1),
      );
      const resultDate2 = new Date(
        timesheetDate.getFullYear(),
        timesheetDate.getMonth(),
        timesheetDate.getDate(),
        parseInt(hours2),
        parseInt(minutes2),
      );

      const description = `${formatDate(el.date)} ${el.startTime} - ${
        el.endTime
      }`;
      const duration = Number(
        (
          getDurationDifference(resultDate1, resultDate2, el.breakTime) /
          1000 /
          60 /
          60
        ).toFixed(2),
      );

      return {
        description,
        duration,
        payAmount: Number((invoice.placement.fee ?? 0).toFixed(2)),
        lineTotal: Number(((invoice.placement.fee ?? 0) * duration).toFixed(2)),
      };
    });

    const totalHoursOnCall = Number(
      (
        invoice.timesheet?.timings?.reduce(
          (total, el) => total + (el?.callHours ?? 0),
          0,
        ) /
        (60 * 60)
      ).toFixed(2),
    );

    const feeOnCall = invoice.placement.onCallFee ?? 0;

    const onCall = {
      totalHours: totalHoursOnCall,
      fee: feeOnCall,
      lineTotal: totalHoursOnCall * feeOnCall,
    };
    const totalNormalHoursLine = (timings ?? []).reduce(
      (total, el) => total + el.lineTotal,
      0,
    );
    const totalLines = Number(
      (totalNormalHoursLine + onCall.lineTotal).toFixed(2),
    );

    const vat = {
      value: Number(invoice.placement.vat),
      result: Number(
        Number(((invoice.placement.vat ?? 0) * totalLines) / 100).toFixed(2),
      ),
    };
    const finalResult = Number((totalLines + vat.result).toFixed(2));

    setData({
      clientAddress,
      reminderDate: stringReminderDate,
      candidateName,
      candidateRole,
      client,
      timings,
      onCall,
      totalLines,
      vat,
      finalResult,
    });
  }, [invoice, company]);

  return (
    <Document>
      <Page size="A4" style={invoicePdfStyle.page}>
        <View style={invoicePdfStyle.section}>
          <>
            <Text style={invoicePdfStyle.title}>Invoice</Text>
            <Text style={invoicePdfStyle.subtitle}>
              Number: {invoice.referenceNumber}
            </Text>
            <Text style={invoicePdfStyle.subtitle}>
              Date: {formatDate(invoice.createdDate)}
            </Text>
            <Text style={invoicePdfStyle.subtitle}>{'\n'}</Text>
            <Text style={invoicePdfStyle.company}>To:</Text>
            <Text style={invoicePdfStyle.company}>
              {invoice.placement?.job?.client.clientName}
            </Text>
            <Text style={invoicePdfStyle.company}>
              {data?.clientAddress?.street}
            </Text>
            <Text style={invoicePdfStyle.company}>
              {data?.clientAddress?.city}
            </Text>
            <Text style={invoicePdfStyle.company}>
              {data?.clientAddress?.county}
            </Text>
            <Text style={invoicePdfStyle.company}>
              {data?.clientAddress?.region}
            </Text>
            <Text style={invoicePdfStyle.company}>
              {data?.clientAddress?.zip}
            </Text>
            <Text style={invoicePdfStyle.subtitle}>{'\n'}</Text>

            <View style={invoicePdfStyle.table}>
              <View style={[invoicePdfStyle.tableRow]}>
                <View
                  style={[
                    invoicePdfStyle.tableCol,
                    invoicePdfStyle.width60,
                    invoicePdfStyle.tableHeader,
                  ]}
                >
                  <Text>Employee Name</Text>
                </View>
                <View
                  style={[
                    invoicePdfStyle.tableCol,
                    invoicePdfStyle.width40,
                    invoicePdfStyle.tableHeader,
                  ]}
                >
                  <Text>Role / Designation</Text>
                </View>
              </View>
              <View style={invoicePdfStyle.tableData}>
                <View style={invoicePdfStyle.tableRow}>
                  <View
                    style={[invoicePdfStyle.tableCol, invoicePdfStyle.width60]}
                  >
                    <Text>{data.candidateName}</Text>
                  </View>
                  <View
                    style={[invoicePdfStyle.tableCol, invoicePdfStyle.width40]}
                  >
                    <Text>{data.candidateRole}</Text>
                  </View>
                </View>
              </View>
            </View>

            <Text style={invoicePdfStyle.subtitle}>{'\n'}</Text>

            <View style={invoicePdfStyle.table}>
              <View style={[invoicePdfStyle.tableRow]}>
                <View
                  style={[
                    invoicePdfStyle.tableCol,
                    invoicePdfStyle.width60,
                    invoicePdfStyle.tableHeader,
                  ]}
                >
                  <Text>Work Location</Text>
                </View>
                <View
                  style={[
                    invoicePdfStyle.tableCol,
                    invoicePdfStyle.width40,
                    invoicePdfStyle.tableHeader,
                  ]}
                >
                  <Text>Reminder Date</Text>
                </View>
              </View>
              <View style={invoicePdfStyle.tableData}>
                <View style={invoicePdfStyle.tableRow}>
                  <View
                    style={[invoicePdfStyle.tableCol, invoicePdfStyle.width60]}
                  >
                    <Text>{invoice.placement?.job?.client.clientSiteName}</Text>
                  </View>
                  <View
                    style={[invoicePdfStyle.tableCol, invoicePdfStyle.width40]}
                  >
                    <Text>{data.reminderDate}</Text>
                  </View>
                </View>
              </View>
            </View>

            <Text style={invoicePdfStyle.subtitle}>{'\n'}</Text>
          </>
          {/* WORKING DAYS TABLE */}
          <View style={invoicePdfStyle.table}>
            <View style={[invoicePdfStyle.tableRow]}>
              <View
                style={[
                  invoicePdfStyle.tableCol,
                  invoicePdfStyle.width60,
                  invoicePdfStyle.tableHeader,
                ]}
              >
                <Text>Description</Text>
              </View>
              <View
                style={[
                  invoicePdfStyle.tableCol,
                  invoicePdfStyle.width10,
                  invoicePdfStyle.tableHeader,
                ]}
              >
                <Text>Units</Text>
              </View>
              <View
                style={[
                  invoicePdfStyle.tableCol,
                  invoicePdfStyle.width10,
                  invoicePdfStyle.tableHeader,
                ]}
              >
                <Text>Unit price</Text>
              </View>
              <View
                style={[
                  invoicePdfStyle.tableCol,
                  invoicePdfStyle.width20,
                  invoicePdfStyle.tableHeader,
                ]}
              >
                <Text>Line total</Text>
              </View>
            </View>
            <View style={invoicePdfStyle.tableData}>
              <>
                {data?.timings?.map((el) => (
                  <View style={invoicePdfStyle.tableRow}>
                    <View
                      style={[
                        invoicePdfStyle.tableCol,
                        invoicePdfStyle.width60,
                      ]}
                    >
                      <Text>{el.description}</Text>
                    </View>
                    <View
                      style={[
                        invoicePdfStyle.tableCol,
                        invoicePdfStyle.width10,
                      ]}
                    >
                      <Text>{convertSecondsToTime(el.duration * 60)}</Text>
                    </View>
                    <View
                      style={[
                        invoicePdfStyle.tableCol,
                        invoicePdfStyle.width10,
                      ]}
                    >
                      <Text>£ {el.payAmount}</Text>
                    </View>
                    <View
                      style={[
                        invoicePdfStyle.tableCol,
                        invoicePdfStyle.width20,
                      ]}
                    >
                      <Text>£ {el.lineTotal}</Text>
                    </View>
                  </View>
                ))}
              </>
            </View>
          </View>
          <Text style={invoicePdfStyle.subtitle}>{'\n'}</Text>

          {/* TOTAL ON CALLS HOUR TABLE */}
          <View style={invoicePdfStyle.table}>
            <View style={invoicePdfStyle.table}>
              <View style={invoicePdfStyle.tableData}>
                <View style={invoicePdfStyle.tableRow}>
                  <View
                    style={[invoicePdfStyle.tableCol, invoicePdfStyle.width60]}
                  >
                    <Text>Total hours on calls</Text>
                  </View>
                  <View
                    style={[invoicePdfStyle.tableCol, invoicePdfStyle.width10]}
                  >
                    <Text>
                      {convertSecondsToTime(data?.onCall?.totalHours * 60)}
                    </Text>
                  </View>
                  <View
                    style={[invoicePdfStyle.tableCol, invoicePdfStyle.width10]}
                  >
                    <Text>£ {data?.onCall?.fee}</Text>
                  </View>
                  <View
                    style={[invoicePdfStyle.tableCol, invoicePdfStyle.width20]}
                  >
                    <Text>£ {data?.onCall?.lineTotal}</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <Text style={invoicePdfStyle.subtitle}>{'\n'}</Text>

          {/* INVOICE RESULT */}
          <View style={invoicePdfStyle.table}>
            <View style={invoicePdfStyle.tableData}>
              <View style={invoicePdfStyle.tableRow}>
                <View
                  style={[invoicePdfStyle.tableCol, invoicePdfStyle.width60]}
                >
                  <Text>#</Text>
                </View>
                <View
                  style={[invoicePdfStyle.tableCol, invoicePdfStyle.width20]}
                >
                  <Text>Subt.</Text>
                </View>
                <View
                  style={[invoicePdfStyle.tableCol, invoicePdfStyle.width20]}
                >
                  <Text>£ {data.totalLines}</Text>
                </View>
              </View>
              <View style={invoicePdfStyle.tableRow}>
                <View
                  style={[invoicePdfStyle.tableCol, invoicePdfStyle.width60]}
                >
                  <Text>#</Text>
                </View>
                <View
                  style={[invoicePdfStyle.tableCol, invoicePdfStyle.width20]}
                >
                  <Text>Vat</Text>
                </View>
                <View
                  style={[invoicePdfStyle.tableCol, invoicePdfStyle.width20]}
                >
                  <Text>
                    £ {data?.vat?.result} ({data?.vat?.value}%)
                  </Text>
                </View>
              </View>
              <View style={invoicePdfStyle.tableRow}>
                <View
                  style={[invoicePdfStyle.tableCol, invoicePdfStyle.width60]}
                >
                  <Text>#</Text>
                </View>
                <View
                  style={[invoicePdfStyle.tableCol, invoicePdfStyle.width20]}
                >
                  <Text>Total</Text>
                </View>
                <View
                  style={[invoicePdfStyle.tableCol, invoicePdfStyle.width20]}
                >
                  <Text>£ {data.finalResult}</Text>
                </View>
              </View>
            </View>
          </View>

          <Text style={invoicePdfStyle.subtitle}>{'\n'}</Text>
          <Text style={invoicePdfStyle.company}>
            Please make payment to the following bank account
          </Text>
          <Text style={invoicePdfStyle.subtitle}>{'\n'}</Text>
          <Text style={invoicePdfStyle.subtitle}>
            Bank name: {company?.bank?.name}
          </Text>
          <Text style={invoicePdfStyle.subtitle}>
            Account title: {company?.bank?.accountName}
          </Text>
          <Text style={invoicePdfStyle.subtitle}>
            Bank account: {company?.bank?.accountNumber}
          </Text>
          <Text style={invoicePdfStyle.subtitle}>
            Sort Code: {company?.bank?.sortNumber}
          </Text>
        </View>
      </Page>
    </Document>
  );
};
